/**
 * Sets features variable in component present in the session storage
 * @param {*} Component
 */

import React from 'react';
import { getItem as getSessionStorageItem } from 'Helpers/sessionStorage';
import hoistNonReactStatic from 'hoist-non-react-statics';

const withSessionFeature = Component => {
    const ComponentWithSessionFeature = props => {
        const storedFeatures = getSessionStorageItem('features') || [];

        return <Component sessionFeatures={ storedFeatures } { ...props } />;
    };

    ComponentWithSessionFeature.displayName = `withSessionFeature(${Component.displayName || Component.name})`;

    return hoistNonReactStatic(ComponentWithSessionFeature, Component);
};

export default withSessionFeature;
