import { MAIN_CATEGORY } from './categories';

export const QUESTION_SEO = {
    MAIN_ENTITY: 'mainEntity',
    ITEM_TYPE: 'https://schema.org/Question',
    ITEM_PROP: 'name'
};

export const ANSWER_SEO = {
    ITEM_TYPE: 'https://schema.org/Answer',
    ITEM_PROP: 'acceptedAnswer'
};

export const MAKE_KEY = 'make';

const commonContentType = {
    CATEGORY_L1_LOCATION_L3: 'category_l1_location_l3',
    CATEGORY_L2_LOCATION_L3: 'category_l2_location_l3'
};

const GENERAL = 'general';

export const FAQS = {
    [MAIN_CATEGORY.BIKES]: {
        ...commonContentType,
        GENERAL,
        BRAND_CATEGORY_L2_LOCATION_L3: 'brand_category_l2_location_l3',
        MODEL_CATEGORY_L2_LOCATION_L3: 'model_category_l2_location_l3'
    },
    [MAIN_CATEGORY.ELECTRONICS]: commonContentType,
    [MAIN_CATEGORY.REAL_ESTATE]: {
        CATEGORY_L1_LOCATION_L3: 'category_l1_location_l3',
        SALE_CATEGORY_L2_LOCATION_L3: 'sale_category_l2_location_l3',
        RENT_CATEGORY_L2_LOCATION_L3: 'rent_category_l2_location_l3'
    },
    [MAIN_CATEGORY.JOBS]: {
        ...commonContentType,
        GENERAL
    },
    [MAIN_CATEGORY.MOBILES]: {
        ...commonContentType,
        GENERAL,
        BRAND_CATEGORY_L2_LOCATION_L3: 'brand_category_l2_location_l3'
    },
    [MAIN_CATEGORY.CARS]: {
        GENERAL,
        WITHOUT_BRAND_WITH_LOCATION: 'without_brand_with_location',
        BRAND_WITHOUT_LOCATION: 'brand_without_location',
        BRAND_WITH_LOCATION: 'brand_with_location'
    },
    NOOP: ''
};

export const FAQ_TRACK = {
    FAQ_CLICKED: 'faq_clicked',
    FAQ_DISPLAYED: 'faq_displayed',
    LISTING_RESULTS: 'listings_results'
};
