import React, { useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import withRouter from 'HOCs/withRouter';
import withTrack from 'HOCs/withTrack/withTrack';

import { getFaq } from 'Actions/faq';

import { categoriesByFilterSelector } from 'Selectors/categories';
import { getAbundanceFilterInfo, getAppliedFiltersLabels, getParsedAppliedFilters } from 'Selectors/filtersTanak';
import { getPopularLocationsLeftBar } from 'Selectors/popularLocations';

import { getFilteredNestedSelect } from 'Helpers/filtersTanak';
import { MAIN_CATEGORY } from 'Constants/categories';
import { FAQ_TRACK, FAQS } from 'Constants/faq';

import {
    getContentForBikesCategory,
    getContentForCarsCategory,
    getContentForElectronicsCategory,
    getContentForJobsCategory,
    getContentForMobilesCategory,
    getContentForRealEstateCategory,
    getFAQTypes,
    getMinimumPrice,
    getMultipleFilterSelected,
    getTransformedMakeModels,
    interpolate
} from './FAQ.helpers';
import FAQ from './FAQ';

export const FAQWrapperV2 = ({
    category,
    faqContent,
    getFaqContents,
    location,
    parentCategoryId,
    track,
    ...restProps
}) => {
    function getTypesAndContent(id) {
        switch (id) {
            case MAIN_CATEGORY.BIKES:
                return getContentForBikesCategory(category.id, parentCategoryId, restProps);
            case MAIN_CATEGORY.ELECTRONICS:
                return getContentForElectronicsCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.JOBS:
                return getContentForJobsCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.REAL_ESTATE:
                return getContentForRealEstateCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.MOBILES:
                return getContentForMobilesCategory(category, parentCategoryId, restProps);
            case MAIN_CATEGORY.CARS:
                return getContentForCarsCategory({ params: restProps.params, selectedFilters: restProps.selectedFilters });
            default: return { content: {}, type: FAQS.NOOP };
        }
    }

    useEffect(() => {
        const type = getFAQTypes(
            category?.id,
            parentCategoryId,
            {
                params: restProps?.params,
                selectedFilters: restProps?.selectedFilters,
                selectedLocation: restProps?.selectedLocation
            }
        );

        track(FAQ_TRACK.LISTING_RESULTS, { faq_available: true });
        if (parentCategoryId) {
            track(FAQ_TRACK.FAQ_DISPLAYED, { category_id: parentCategoryId });
        }

        getFaqContents(type, {});
    }, [getFaqContents, location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (restProps?.selectedFilters?.length > 0) {
            const type = getFAQTypes(
                category?.id,
                parentCategoryId,
                {
                    params: restProps?.params,
                    selectedFilters: restProps?.selectedFilters,
                    selectedLocation: restProps?.selectedLocation

                }
            );

            getFaqContents(type, {});
        }
    }, [restProps?.selectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    let title = '';
    const content = [];

    const isMultipleFilterSelected = getMultipleFilterSelected(restProps?.selectedFilters || []);

    if (faqContent?.title && faqContent?.content?.length > 0) {
        title = interpolate(faqContent?.title, restProps.selectedLocation?.name, getTypesAndContent(parentCategoryId)?.content);

        for (const c of (faqContent?.content || [])) {
            const payload = getTypesAndContent(parentCategoryId)?.content;
            const regex = new RegExp(/\{(top_models|popular_areas|job_types|number_of_bikes|number_of_scooters|number_of_bicycles|number_of_items|number)\}/, 'g');

            if (regex.test(c.a)) {
                if (
                    ('top_models' in payload && payload.top_models?.length > 0)
                    || ('popular_areas' in payload && payload.popular_areas?.length > 0)
                    || ('job_types' in payload && payload.job_types?.length > 0)
                    || ('number_of_bikes' in payload && payload.number_of_bikes > 0)
                    || ('number_of_scooters' in payload && payload.number_of_scooters > 0)
                    || ('number_of_bicycles' in payload && payload.number_of_bicycles > 0)
                    || ('number_of_items' in payload && payload.number_of_items > 0)
                    || ('number' in payload && payload.number > 0)
                ) {
                    content.push({
                        ...(c?.list ? { list: c.list } : {}),
                        q: interpolate(c?.q, restProps.selectedLocation?.name, payload),
                        a: interpolate(c?.a, restProps.selectedLocation?.name, payload)
                    });
                }
            }
            else {
                content.push({
                    ...(c?.list ? { list: c.list } : {}),
                    q: interpolate(c?.q, restProps.selectedLocation?.name, payload),
                    a: interpolate(c?.a, restProps.selectedLocation?.name, payload)
                });
            }
        }
    }

    return isMultipleFilterSelected ? '' : <FAQ { ...({ content, id: parentCategoryId, title, track }) } />;
};

FAQWrapperV2.propTypes = {
    category: PropTypes.object,
    categoryFilters: PropTypes.object,
    faqContent: PropTypes.object,
    selectedFilters: PropTypes.array,
    getFaqContents: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
        formatNumber: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
        query: PropTypes.object,
        pathname: PropTypes.string
    }),
    parentCategoryId: PropTypes.string,
    selectedLocation: PropTypes.object,
    topModels: PropTypes.array,
    track: PropTypes.func
};

FAQWrapperV2.defaultProps = {
    params: {}
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    const { params: { categoryID }} = ownProps;

    return {
        getFaqContents: (type, content) => dispatch(getFaq(categoryID, type, content))
    };
};

const getParams = props => ({
    categoryId: props.params.categoryID,
    locationId: props.params.geoID,
    search: props.params.text,
    params: props.location.query,
    constructionStatus: props.params.constructionStatus,
    type: props.type || ''
});

export const mapStateToProps = (state, ownProps) => {
    const { config, params } = ownProps;
    const categoryId = params?.categoryID;

    const { categories, faq, locations } = state;

    const category = categories?.elements[categoryId];
    const categoriesInfo = categoriesByFilterSelector(state);
    const parentCategoryId = category?.parent_id === 0 ? category?.id : category?.parent_id;

    const filtersInfoByAttr = getAbundanceFilterInfo(state, categoryId, config);
    const appliedFiltersInfo = getParsedAppliedFilters(state, categoryId);

    const transformedFilterInfo = getFilteredNestedSelect(filtersInfoByAttr?.make || {}, filtersInfoByAttr, '', appliedFiltersInfo);
    const minPrice = getMinimumPrice(state);

    return {
        category,
        // get to the number of ads for sub_categories.
        categoryFilters: categoriesInfo.find(cf => cf?.id === parentCategoryId),
        // get faq content from redux state to render on the UI.
        faqContent: faq?.elements[parentCategoryId],
        locations: getPopularLocationsLeftBar(state, getParams(ownProps)),
        // get the minimum price from the item list.
        minPrice,
        // the parent category id.
        parentCategoryId,
        // get the list of selected filters.
        selectedFilters: getAppliedFiltersLabels(state, categoryId),
        selectedLocation: locations?.selectedLocation,
        topModels: getTransformedMakeModels(transformedFilterInfo?.model || {})
    };
};

export default compose(
    injectIntl,
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(FAQWrapperV2);
