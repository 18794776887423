/**
 * Get vehicle parameters for different markets in case they are different from the default ones.
 * @param parameters
 * @returns {{bodyType: *, color: *, fuelType: *, year: *, variant: *, vehicleTransmission: *, mileage: *}}
 */
const getVehicleParameters = parameters => {
    const make = parameters.find(parameter => parameter.key === 'make')?.value;
    const model = parameters.find(parameter => parameter.key === 'model')?.value;
    const variant = parameters.find(parameter => parameter.key === 'variant')?.value;
    const year = parameters.find(parameter => parameter.key === 'year')?.value;
    const vehicleTransmission = parameters.find(parameter => parameter.key === 'transmission')?.value;
    const bodyType = parameters.find(parameter => parameter.key === 'body_type')?.value;
    const color = parameters.find(parameter => parameter.key === 'color')?.value;
    const fuelType = parameters.find(parameter => parameter.key === 'petrol')?.value;
    const mileage = parameters.find(parameter => parameter.key === 'km_driven')?.value ?? parameters.find(parameter => parameter.key === 'mileage')?.value;

    return { make, model, variant, year, vehicleTransmission, bodyType, color, fuelType, mileage };
};

/**
 * Build the JSON LD schema for a vehicle item.
 * @param props
 * @returns {{mainEntity: {bodyType: *, offers: {image: *, priceCurrency: *, '@type': string, price: *, availabilityStarts: *}, vehicleConfiguration: *, color: *, vehicleEngine: [{fuelType: *, '@type': string}], '@type': string, modelDate: *, mileageFromOdometer: {'@type': string, unitCode: string, value: *}, name, steeringPosition: (string), vehicleTransmission: *}, breadcrumb: {itemListElement, '@type': string, numberOfItems: string}, '@type': string, name, description, inLanguage, '@context': string, url: string}}
 */
const buildProduct = props => {
    const { item, app, breadcrumbLinks, location, configGeneral, allItemImages } = props;
    const { title, description, status, images } = item;
    const host = app.location.hostname;
    const protocol = configGeneral.location.country.protocol;
    const vehicleParameters = item.parameters;
    const url = `${protocol}://${host}${location.pathname}`;
    const { make, model, variant, year, vehicleTransmission, bodyType, color, fuelType, mileage } = getVehicleParameters(vehicleParameters);
    const steeringPosition = 'https://schema.org/RightHandDriving';
    const price = item.price.value.raw || 0;
    const priceCurrency = item.price.value.currency.iso_4217 || 'NA';
    const image = allItemImages[0].url;
    const inspectionDate = item.inspectionDetails.additionalInfo.parameters.find(parameter => parameter.key === 'inspectionDate')?.value;
    const getItemListElement = links => links.slice(0, -1).map((link, index) => {
        const name = link.text || link.translation?.values?.category || link.translation?.id;

        return {
            '@type': 'ListItem',
            position: index,
            item: {
                '@id': `${protocol}://${host}${link.href}`,
                name
            }
        };
    });

    return {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name: title,
        description,
        url,
        inLanguage: app.lang,
        breadcrumb: {
            '@type': 'BreadcrumbList',
            numberOfItems: '4',
            itemListElement: getItemListElement(breadcrumbLinks)
        },
        mainEntity: {
            '@type': 'Car',
            image: images && images.length && images.map(img => img.url) || [],
            name: title,
            vehicleConfiguration: variant,
            vehicleModelDate: year,
            vehicleIdentificationNumber: 'XXXXXXXXXXXXXXXXX',
            bodyType: bodyType || 'NA',
            itemCondition: 'https://schema.org/UsedCondition',
            steeringPosition,
            color: color || 'NA',
            vehicleTransmission,
            vehicleEngine: [
                {
                    '@type': 'EngineSpecification',
                    fuelType: fuelType || 'NA'
                }
            ],
            mileageFromOdometer: {
                '@type': 'QuantitativeValue',
                value: parseInt(mileage, 10) || 1,
                unitCode: 'KMT'
            },
            offers: {
                '@type': 'Offer',
                availability: status && status.status === 'active' ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
                price,
                priceCurrency,
                availabilityStarts: inspectionDate,
                image

            },
            brand: {
                '@type': 'Brand',
                name: make || 'Unknown'
            },
            model,
            vehicleInteriorColor: 'NA',
            vehicleInteriorType: 'Standard',
            vehicleSeatingCapacity: 5,
            numberOfDoors: 4,
            driveWheelConfiguration: 'https://schema.org/FrontWheelDriveConfiguration'
        }
    };
};

export default buildProduct;
