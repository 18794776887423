import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, Heading } from 'panamera-react-ui';
import Button from 'Components/Button/Button';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import css from './ConfirmModal.scss';
import { FormattedMessage as Translation } from 'react-intl';
import classNames from 'classnames';
import { DELETE_CHAT_MODAL_ID, NOTIFICATION_OPT_OUT } from 'Constants/letgo';

export const ConfirmModal = ({ title, titleCase, titleAlign, titleWordBreak, titleWeight, text, onAccept, onDecline, acceptText, declineText, isOpened, reverseBtnOrder, stopEmailRegistrationModal, showIcon, onClose, confirmModalMxCl, isOtoplus, iconName, iconColor, modalId }) => {
    let btns = [];
    const textColor = modalId === NOTIFICATION_OPT_OUT ? 'blueDark1' : 'blackSecondaryText';
    const headingColor = stopEmailRegistrationModal ? 'blackText' : textColor;

    if (isOpened) {
        btns = [
            {
                key: 'confirm-modal-accept-button',
                onClick: onAccept,
                type: 'primary',
                'data-aut-id': 'accept',
                className: stopEmailRegistrationModal ? css.btnNew : css.btn,
                text: acceptText
            },
            {
                key: 'confirm-modal-decline-button',
                onClick: onDecline,
                type: 'outline',
                'data-aut-id': 'decline',
                className: stopEmailRegistrationModal ? css.btnNew : css.btn,
                text: declineText
            }
        ];

        // To interchange the btn for better usage
        if (reverseBtnOrder) {
            btns.reverse();
        }
    }

    return (
        <React.Fragment>
            { isOpened
                && <Overlay
                    isOpened={ isOpened }
                    closeOnOutsideClick={ false }
                    contentClassName={ classNames(css.confirmModal,
                        { [css.deleteChat]: modalId === DELETE_CHAT_MODAL_ID, [css.notificationOptOut]: modalId === NOTIFICATION_OPT_OUT }) }
                    overlayClassName={ classNames(css.overlay, { [css.otoplusOverlay]: isOtoplus }) }
                >
                    { showIcon
                        && <IconWrapper
                            className={ classNames(css.close, { [css.closeMxCl]: confirmModalMxCl }) }
                            data-aut-id="btnClose"
                            icon={ iconName }
                            onClick={ onClose }
                            color={ iconColor }
                            size={ confirmModalMxCl ? 20 : 25 } // eslint-disable-line no-magic-numbers
                        />
                    }
                    <React.Fragment>
                        { title
                            && <div
                                className={ classNames(
                                    css.title,
                                    { [css[titleCase]]: titleCase },
                                    { [css[titleAlign]]: titleAlign },
                                    { [css[titleWordBreak]]: titleWordBreak },
                                    { [css[titleWeight]]: titleWeight },
                                    { [css.saveIconSpace]: showIcon }
                                ) }
                            >
                                <Heading
                                    color="blackText"
                                    tag="div"
                                    type="subtitle"
                                    data-aut-id="title"
                                >
                                    { title }
                                </Heading>

                            </div>
                        }
                        { text
                            && <div
                                className={ classNames(css.text, { [css.modifiedTextStyle]: confirmModalMxCl }, { [css.textOtoplus]: isOtoplus }) }
                            >
                                <Heading
                                    color={ headingColor }
                                    tag="div"
                                    type="body1"
                                    fontWeight="normal"
                                    data-aut-id="text"
                                >
                                    { text }
                                </Heading>
                            </div>
                        }
                        <div className={ classNames(css.buttons, { [css.modifiedButtonStyle]: confirmModalMxCl }, { [css.singleButtonStyle]: confirmModalMxCl }) } >
                            {btns.map(props => (
                                <Button { ...props } >{props.text}</Button>
                            ))}
                        </div>
                    </React.Fragment>
                </Overlay>
            }
        </React.Fragment>
    );
};

ConfirmModal.propTypes = {
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    title: PropTypes.node,
    titleCase: PropTypes.string,
    titleAlign: PropTypes.oneOf(['left', 'center', 'right']),
    titleWordBreak: PropTypes.oneOf(['all', 'word', '']),
    titleWeight: PropTypes.oneOf(['normal', 'bold']),
    text: PropTypes.node,
    acceptText: PropTypes.node,
    declineText: PropTypes.node,
    isOpened: PropTypes.bool,
    reverseBtnOrder: PropTypes.bool,
    stopEmailRegistrationModal: PropTypes.bool,
    onClose: PropTypes.func,
    showIcon: PropTypes.bool,
    confirmModalMxCl: PropTypes.bool,
    isOtoplus: PropTypes.bool,
    iconName: PropTypes.string,
    iconColor: PropTypes.string,
    modalId: PropTypes.string
};

ConfirmModal.defaultProps = {
    title: '',
    titleCase: '',
    titleAlign: 'center',
    titleWordBreak: 'all',
    titleWeight: 'bold',
    text: '',
    acceptText: <Translation id="cookies_banner_accept_button" />,
    declineText: <Translation id="cancelShareContact" />,
    isOpened: false,
    reverseBtnOrder: false,
    stopEmailRegistrationModal: false,
    showIcon: false,
    iconName: 'cross',
    iconColor: 'blackIcons',
    isOtoplus: false,
    modalId: ''
};

export default ConfirmModal;
